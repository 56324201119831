import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppInput from "../Shared/AppInput";
import {useFormik} from "formik";
import * as yup from 'yup';
import MyOpinionLogo from '../../assets/images/MyOpinion_Logo_Head.png';
import useAlert from "../../hooks/useAlert";
import httpService from "../../services/http.service";
import AppAlert from "../Shared/AppAlert";
import {extractResponseValidationErrors} from "../../utils/index";
import {loggedOut} from "../../actions/authAction";
import {connect} from "react-redux";
import * as jwtService from "../../services/jwt.service";

const ForgotPassword = ({history, match, location, loggedOut, ...props}) => {

	const initialValues = {
		email: ''
	};

	const [submitting, setSubmitting] = useState(false);
	const [alert, setAlert, onClose] = useAlert();

	useEffect(() => {
		jwtService.remove(loggedOut);
	}, []);

	const onSubmit = async (values) => {
		setSubmitting(true);
		setAlert(null);

		const link = `${window.location.protocol}//${window.location.hostname}`

		const formData = {
			...values,
			link
		};

		try {

			await httpService.post(`/auth/reset-password`, formData);

			setAlert({
				type: 'success',
				message: 'Mot de passe réinitialisé, merci de consulter vos courriels !'
			});

			formik.resetForm(initialValues);

			setTimeout(() => {
				history.replace('/login');
			}, 1500);
		} catch ({response}) {
			const {data, status} = response;

			if(response && status == 422) {
				setAlert({
					type: 'danger',
					message: extractResponseValidationErrors(data)[0]
				})
			}
			else if(response && status == 500) {
				setAlert({
					type: 'danger',
					message: 'Erreur de traitement, veuillez contacter les administrateurs'
				})
			}
		} finally {
			setSubmitting(false);
		}
	}

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object().shape({
			email: yup.string().ensure().email().required('Champ obligatoire')
		}),
		onSubmit
	})

	return (
		<>
			<div className="pattern">
				<span className="red"/>
				<span className="indigo"/>
				<span className="blue"/>
				<span className="green"/>
				<span className="orange"/>
			</div>
			<div className="auth-main particles_js">
				<div className="auth_div vivify popIn">
					<div className="auth_brand">
						<Link className="navbar-brand" to="/">
							<img src={MyOpinionLogo} width={process.env.REACT_APP_AUTH_LOGO_HEIGHT} height={process.env.REACT_APP_AUTH_LOGO_WIDTH} className="d-inline-block align-top mr-2" alt={process.env.REACT_APP_APP_NAME} />
						</Link>
					</div>
					<div className="card forgot-pass">
						<div className="body">
							<p className="lead mb-3"><strong>Oups</strong>,<br /> Un soucis ?</p>
							<p>Entrez votre adresse email pour réinitialiser votre mot de passe.</p>
							<form className="form-auth-small" onSubmit={formik.handleSubmit}>
								{alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
								<AppInput
									label="Adresse email"
									type="email"
									name="email"
									error={formik.errors.email}
									touched={formik.touched.email}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
									required
								/>
								<button type="submit" disabled={submitting} className="btn btn-round btn-primary btn-lg btn-block font-weight-bold">
									{submitting ? 'Réinitialisation encour...' : 'Réinitialiser mon mot de passe'}
								</button>
								<div className="bottom">
									<span className="helper-text">Retour a la page de de <Link to="/login">connexion</Link></span>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div id="particles-js"></div>
			</div>
		</>
	);
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
	loggedOut: (data) => dispatch(loggedOut(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

import React, {useEffect, useState} from 'react';
import httpService from "../../../services/http.service";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import {Link} from "react-router-dom";
import AppOpinion from "../../Shared/AppOpinion";

const AgoraOpinions = ({history, location, match, ...props}) => {

    const [fetching, setFetching] = useState(true);
    const [agora, setAgora] = useState([]);

    const fetchAgora = async (id, cb = () => null) => {
        const { data: response } = await httpService.get(`/agoras/${id}`);
        setAgora(response.data);
        cb();
    };

    useEffect(() => {

        try {

            fetchAgora(match.params.id, () => {
                setFetching(false);
            });

        } catch (e) {
            throw e;
        }

    }, []);

    if(fetching) {
        return <FecthingSpinner />
    }

    return (
        <div className="container-fluid">
            <AppBreadcrumb title={
                process.env.REACT_APP_SOCIAL_HUB ? `${agora.ago_title}: Historique des posts publiés` : `${agora.ago_title}: Historique des opinions publiées`
            }>
                <div className="col-md-6 col-sm-12 text-right">
                    <Link to={'/agoras'}>
                        <button className="btn btn-sm btn-default font-weight-bold mr-1">
                            <i className="fa fa-arrow-left"></i>{' '}
                            <span>Retour</span>
                        </button>
                    </Link>
                    <Link to={`/agoras/${agora.ago_id}/members`}>
                        <button className="btn btn-sm btn-success font-weight-bold">
                            <i className="fa fa-users"></i>{' '}
                            <span>Membres</span>
                        </button>
                    </Link>
                </div>
            </AppBreadcrumb>

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card">
                        <div className="header"><h2>{agora.ago_presentation || '-'}</h2></div>
                        {agora.opinions.length == 0 && <h3 className="text-center text-muted mt-3">
                            {process.env.REACT_APP_SOCIAL_HUB ? "Aucun post publié" : "Aucune opinion publiée"}
                        </h3>}
                        <div className="testimonials">
                            {agora.opinions.map(opinion => (
                                <AppOpinion
                                    user={opinion.user}
                                    opinion={opinion}
                                    key={opinion.sond_id}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AgoraOpinions;
import React from 'react';
import {getUserFullName} from "../../../utils/index";
import * as jwtService from './../../../services/jwt.service'

const UserProfile = ({user, onDelete = () => null, togglePublicPostAllowed = () => null, onRestoreAuthorisation = () => null, ...props}) => {

    const authUser = jwtService.get();

    return (
        <>
            <div className="row">
                <div className="card">
                    <div className="body">
                        {user.user_verified && (<p>
                            <span className="badge badge-success" title="Compte certifie">Compte certifié</span>
                        </p>)}
                        {user.user_gold && (<p>
                            <span className="badge badge-warning" title="Compte premium">Compte premium</span>
                        </p>)}

                        {process.env.REACT_APP_SOCIAL_HUB && (
                            <p>
                                <small className="text-muted">Autorisation de poster dans le fil public ({process.env.REACT_APP_PUBLIC_FEED_NAME})</small>
                                <p>
                                    <label className="switch">
                                        <input type="checkbox" onChange={togglePublicPostAllowed} checked={user.user_public_post_allowed} />
                                        <span className="slider"></span>
                                    </label>
                                </p>
                            </p>
                        )}
                        <small className="text-muted">Identifiant</small>
                        <p>#{user.user_id}</p>
                        <small className="text-muted">Nom d'utilisateur</small>
                        <p>@{user.user_username}</p>
                        <small className="text-muted">Nom complet</small>
                        <p>{getUserFullName(user)}</p>
                        <small className="text-muted">Titre</small>
                        <p>{user.profile.prof_title || '-'}</p>
                        <small className="text-muted">Bio</small>
                        <p>{user.profile.prof_description || '-'}</p>
                        <small className="text-muted">Email</small>
                        <p>{user.user_email}</p>
                        <small className="text-muted">Statut du profil</small>
                        <p>
                            {user.user_active ? (
                                <span className="badge badge-success" title="Actif">Actif</span>
                            ) : (
                                <span className="badge badge-danger" title="Inactif">Inactif</span>
                            )}
                        </p>
                        <small className="text-muted">Pays</small>
                        <p>
                            <img width={32} src={user?.ip_country?.ip_country_flag} alt={user?.ip_country?.ip_country_name}/> {user.user_ip_pays || '-'}
                        </p>
                        <small className="text-muted">Membre depuis le</small>
                        <p>{user.user_created_at}</p>

                        {(process.env.REACT_APP_SOCIAL_HUB && authUser?.isRoot) && (
                            <>
                                {!user.user_delete_at && (
                                    <button className="btn btn-danger btn-sm mr-1 font-weight-bold" onClick={onDelete}>
                                        <i className="fa fa-trash-o"></i>{' '}
                                        <span>Supprimer le compte</span>
                                    </button>
                                )}
                                {!user?.authorization && (
                                    <button className="btn btn-success btn-sm mr-1 font-weight-bold" onClick={onRestoreAuthorisation}>
                                        <i className="fa fa-recycle"></i>{' '}
                                        <span>Restaurer l'autorisation</span>
                                    </button>
                                )}
                            </>
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}

export default UserProfile;
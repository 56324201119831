import React, {useEffect, useState} from 'react';
import AppBreadcrumb from "../Shared/AppBreadcrumb";
import queryString from "query-string";
import httpService from "../../services/http.service";
import FecthingSpinner from "../Shared/FecthingSpinner";
import AppPagination from "../Shared/AppPagination";
import {truncate} from "../../utils";
import moment from "moment/moment";
import AppDatepicker from "../Shared/AppDatePicker";
import {useFormik} from "formik";
import AppSelect from "../Shared/AppSelect";
import {connect} from "react-redux";

const Logs = ({authUser, history, location, match, ...props}) => {

    const query = queryString.parse(location.search);

    const initialValues = {
        causer_id: query?.causer_id,
        page: query?.page || 1,
        start_at: query?.start_at ? moment(query?.start_at, 'DD-MM-YYYY').toDate() : '',
        end_at: query?.end_at ? moment(query?.end_at, 'DD-MM-YYYY').toDate() : '',
    }

    const [fetching, setFetching] = useState(true);
    const [logs, setLogs] = useState([]);
    const [moderators, setModerators] = useState([]);
    const [paginationData, setPaginationData] = useState({
        currentPage:  query?.page || 1,
        lastPage: Infinity,
        hasNext: true,
        hasPrevious: false,
        totalItems: Infinity
    });

    const fetchModerators = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/moderateurs`,);
        setModerators(response?.data);
        cb(response?.data);
    }

    const fetchLogs = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/activity_logs`, {
            params
        });
        setLogs(response.data);
        setPaginationData(state => ({
            ...state,
            currentPage: response?.meta?.current_page,
            lastPage: response?.meta?.last_page,
            totalItems: response?.meta?.total,
            hasNext: !!response?.links?.next,
            hasPrevious: !!response?.links?.prev
        }));
        cb();
    };

    const handlePagePaginate = async (direction) => {

        let page = parseInt(query?.page || 1);

        const nextPage = direction === 'previous' ? --page : ++page;

        const data = {
            causer_id: query?.causer_id || '',
            page: nextPage,
            start_at: query?.start_at || '',
            end_at: query?.end_at || ''
        };

        history.push(`/logs?${queryString.stringify(data)}`);

    }

    const onReset = () => {
        formik.resetForm({
            start_at: '',
            end_at: '',
            causer_id: '',
            page: '',
        });

        history.push(`/logs`);
    };

    const onSubmit = async (values) => {

        console.log(values)

        const queryObj = {
            ...initialValues,
            causer_id: values?.causer_id || '',
            start_at: values.start_at ? moment(values.start_at).format('DD-MM-YYYY') : '',
            end_at: values.end_at ? moment(values.end_at).format('DD-MM-YYYY') : '',
            page: 1
        };

        history.push(`/logs?${queryString.stringify(queryObj)}`);

    };

    useEffect(() => {
        setFetching(true);

        try {

            fetchModerators();

            fetchLogs({
                causer_id: query?.causer_id,
                page: query?.page || 1,
                start_at: query?.start_at,
                end_at: query?.end_at,
            }, () => {
                setFetching(false);
            })

        } catch (e) {
            throw e;
        }

    }, [
        query?.causer_id,
        query?.page,
        query?.start_at,
        query?.end_at,
    ]);

    const formik = useFormik({
        initialValues,
        onSubmit
    });

    return (
        <>
            <div className="container-fluid">
                <AppBreadcrumb title="Rapport d'activités"/>

                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card border shadow-sm">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="card-header bg-info text-white font-weight-bold">
                                    <i className="fa fa-search"></i>{' '}
                                    <span>Filtre de recherche</span>
                                </div>
                                <div className="card-body">
                                    {authUser?.isRoot && (
                                        <div className="row">
                                            <div className="col-md-6">
                                                <AppSelect
                                                    placeholder="Administrateur / Moderateur"
                                                    name="causer_id"

                                                    value={formik.values.causer_id}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.errors.causer_id}
                                                    touched={formik.touched.causer_id}
                                                >
                                                    {moderators.map(obj => (
                                                        <option key={obj?.id} value={obj?.id}>{obj?.nom_complet} ({obj?.email})</option>
                                                    ))}
                                                </AppSelect>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <AppDatepicker
                                                placeholder="Debut période"
                                                className="form-control"
                                                error={formik.errors.start_at}
                                                touched={formik.touched.start_at}
                                                value={formik.values.start_at}
                                                onChange={(date) => formik.setFieldValue('start_at', date)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <AppDatepicker
                                                placeholder="Fin période"
                                                className="form-control"
                                                error={formik.errors.end_at}
                                                touched={formik.touched.end_at}
                                                value={formik.values.end_at}
                                                onChange={(date) => formik.setFieldValue('end_at', date)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer bg-white text-right">
                                    <button type="button" onClick={onReset} className="btn btn-danger mr-1">
                                        <i className="fa fa-times"></i>{' '}
                                        <span className="font-weight-bold">Annuler</span>
                                    </button>
                                    <button type="submit" className="btn btn-success">
                                        <i className="fa fa-check"></i>{' '}
                                        <span className="font-weight-bold">Rechercher</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {fetching ? <FecthingSpinner /> : (
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table header-border table-hover table-custom spacing5">
                                    <thead>
                                    <tr>
                                        <th>Modérateur</th>
                                        <th>Email</th>
                                        <th>Description</th>
                                        <th>Adresse IP</th>
                                        <th>Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {logs.map(obj => (
                                        <tr>
                                            <td title={obj?.causer?.nom_complet}>{truncate(obj?.causer?.nom_complet, 20)}</td>
                                            <td>{obj?.causer?.email}</td>
                                            <td title={obj?.description}>{obj?.description ? truncate(obj?.description, 50) : '-'}</td>
                                            <td>{obj?.properties?.ip}</td>
                                            <td>{obj?.created_at}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="body">
                                    <AppPagination
                                        onNavigate={handlePagePaginate}
                                        currentPage={paginationData.currentPage}
                                        hasNext={paginationData.hasNext}
                                        hasPrevious={paginationData.hasPrevious}
                                        totalItems={paginationData.totalItems}
                                        lastPage={paginationData.lastPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )

};

const mapStateToProps = (state) => ({
    authUser: state.auth.user
})

const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(Logs);
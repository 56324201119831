import React, {useEffect, useState} from 'react';
import httpService from "../../../services/http.service";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppInput from "../../Shared/AppInput";
import {useFormik} from "formik";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import UserCard from "../../Shared/User/Card";
import * as queryString from "query-string";
import {USER_TYPE_PAGE, USER_TYPE_USER} from "../../../utils/index";
import AppPagination from "../../Shared/AppPagination";

const UserList = ({history, match, location,...props}) => {

    const query = queryString.parse(location.search);

    const [users, setUsers] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [paginationData, setPaginationData] = useState({
        currentPage:  query?.page || 1,
        lastPage: Infinity,
        hasNext: true,
        hasPrevious: false,
        totalItems: Infinity,
    });

    const initialValues = {
        user_id: query?.user_id || '',
        user_username: query?.user_username || '',
        user_name: query?.user_name || '',
        user_surname: query?.user_surname || '',
        user_email: query?.user_email || '',
        user_type: query?.user_type || '',
        page: query?.page || 1
    };

    const fetchUser = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/users`, {
            params
        });
        setUsers(response.data);
        setPaginationData(state => ({
            ...state,
            currentPage: response?.meta?.current_page,
            lastPage: response?.meta?.last_page,
            totalItems: response?.meta?.total,
            hasNext: !!response?.links?.next,
            hasPrevious: !!response?.links?.prev
        }));
        cb();
    }

    const onReset = () => {
        formik.resetForm({
            user_id: '',
            user_username: '',
            user_name: '',
            user_surname: '',
            user_email: '',
            page: 1,
            user_type: query?.user_type
        });

        history.push(`/users?user_type=${query?.user_type}`);
    }

    const onSubmit = (values) => {

        setFetching(true);

        const data = {
            ...initialValues,
            ...values,
            page: 1
        };

        history.push(`/users?${queryString.stringify(data)}`);

    };

    const handlePagePaginate = async (direction) => {

        let page = parseInt(query?.page || 1);

        const nextPage = direction === 'previous' ? --page : ++page;

        const data = {
            page: nextPage,
            user_id: query?.user_id || '',
            user_username: query?.user_username || '',
            user_name: query?.user_name || '',
            user_surname: query?.user_surname || '',
            user_email: query?.user_email || '',
            user_type: query?.user_type || '',
        };

        history.push(`/users?${queryString.stringify(data)}`);

    };

    useEffect(() => {
        setFetching(true)

        try {
            fetchUser({
                user_id: query?.user_id || '',
                user_username: query?.user_username || '',
                user_name: query?.user_name || '',
                user_surname: query?.user_surname || '',
                user_email: query?.user_email || '',
                user_type: query?.user_type || '',
                page: query?.page || 1
            }, () => setFetching(false));
        } catch (e) {
            throw e;
        }

    }, [
        query?.user_id,
        query?.user_username,
        query?.user_name,
        query?.user_surname,
        query?.user_email,
        query?.user_type,
        query?.page,
    ]);

    const formik = useFormik({
        initialValues,
        onSubmit
    });

    return (
        <div className="container-fluid">
            <AppBreadcrumb title={query?.user_type === USER_TYPE_PAGE ? 'Pages' : 'Utilisateurs'} />

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card border shadow-sm">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-header bg-info text-white font-weight-bold">
                                <i className="fa fa-search"></i>{' '}
                                <span>Filtre de recherche</span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-7">
                                        <AppInput
                                            name="user_id"
                                            placeholder="Identifiant"
                                            value={formik.values.user_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-5">
                                        <AppInput
                                            name="user_username"
                                            placeholder="Nom d'utilisateur"
                                            value={formik.values.user_username}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={query?.user_type === USER_TYPE_PAGE ? `col-md-12` : `col-md-6`}>
                                        <AppInput
                                            placeholder="Nom"
                                            name="user_surname"
                                            value={formik.values.user_surname}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    {query?.user_type !== USER_TYPE_PAGE && (
                                        <div className="col-md-6">
                                            <AppInput
                                                placeholder="Prénom"
                                                name="user_name"
                                                value={formik.values.user_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    )}
                                </div>
                                <AppInput
                                    placeholder="Adresse email"
                                    name="user_email"
                                    value={formik.values.user_email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <div className="card-footer bg-white text-right">
                                <button type="button" onClick={onReset} className="btn btn-danger mr-1">
                                    <i className="fa fa-times"></i>{' '}
                                    <span className="font-weight-bold">Annuler</span>
                                </button>
                                <button type="submit" className="btn btn-success">
                                    <i className="fa fa-check"></i>{' '}
                                    <span className="font-weight-bold">Rechercher</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    {users.map(user => (
                        <div className="col-md-6" key={user?.user_id}>
                            <UserCard user={user}/>
                        </div>
                    ))}
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <AppPagination
                                    onNavigate={handlePagePaginate}
                                    currentPage={paginationData.currentPage}
                                    hasNext={paginationData.hasNext}
                                    hasPrevious={paginationData.hasPrevious}
                                    totalItems={paginationData.totalItems}
                                    lastPage={paginationData.lastPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserList
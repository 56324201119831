import React, {useState, useEffect} from 'react';
import FecthingSpinner from "../../Shared/FecthingSpinner";
import httpService from "../../../services/http.service";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {MONTH, VERIFIED} from "../../../utils/index";

const UserSubscriptions = ({user, ...props}) => {

    const [fectching, setFectching] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);

    const fetchSubscriptions = async (username, cb = () => null) => {
        const { data: response } = await httpService.get(`/users/${username}/subscriptions`);
        setSubscriptions(response.data);
        cb();
    };

    useEffect(() => {

        try {
            fetchSubscriptions(user.user_username, () => {
                setFectching(false);
            });
        } catch (e) {
            throw e;
        }

    }, []);

    if(fectching) {
        return <FecthingSpinner />
    }

    return (
        <div className="row clearfix">
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-hover table-custom spacing8">
                        <thead>
                        <tr>
                            <th>Durée</th>
                            <th className="text-center">Prix (USD)</th>
                            <th className="text-center">Type d'abonnement</th>
                            <th>Période</th>
                        </tr>
                        </thead>
                        <tbody>
                        {subscriptions.map(obj => (
                            <tr key={obj?.subscr_id}>
                                <td>
                                    {obj?.subscr_period === MONTH ? '1 Mois' : '1 an'}
                                </td>
                                <td className="text-center">
                                    {obj?.subscr_price == 0 ? (<span className="badge badge-success  ml-0 mr-0">Gratuit</span>) : Number.parseFloat(obj?.subscr_price).toFixed(2) + ' USD'}
                                </td>
                                <td className="text-center">
                                    {obj?.subscr_type === VERIFIED
                                        ? <i style={{cursor: 'pointer'}} title="Verified" className="fa fa-check-circle-o fa-2x text-primary"/>
                                        : <i style={{cursor: 'pointer'}} title="Premium / Gold" className="fa fa-star fa-2x text-warning"/>}
                                </td>
                                <td>
                                    Du {obj?.subscr_start_at} au {obj?.subscr_ends_at}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default UserSubscriptions;
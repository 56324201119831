import React, {useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import MyOpinionLogo from "../../assets/images/MyOpinion_Logo_Head.png";
import {truncate} from "../../utils";
import {getUserFullName} from "../../utils/index";
import httpService from "../../services/http.service";

const AppComment = ({user, comment: data, toggleMute = () => null, shouldTruncate = true, truncateLength = 200, ...props}) => {

    const [comment, setComment] = useState(data);

    const routeMatch = useRouteMatch();

    const handleToggle = async (comment) => {
        if(!window.confirm('Confirmer ?')) return;

        try {
            await httpService.put(`/comments/${comment.com_id}/toggleMute`);
            setComment(comment => ({
                ...comment,
                com_mute: !comment.com_mute
            }));
        } catch (e) {
            throw e;
        }

    }

    return (
        <div className="card mb-1">
            <div className="body">
                <div className="bs-example" data-example-id="media-alignment">
                    <div className="media" key={comment.com_id}>
                        <div className="media-left">
                            <Link to={`/users/${user.user_username}`}>
                                <img alt="" className="media-object rounded" src={user?.profile?.prof_picture || MyOpinionLogo} width="64" height="64" />
                            </Link>
                        </div>
                        <div className="media-body">
                            <h4 className="media-heading">
                                {getUserFullName(user, true)}{' '}{comment.com_type === 's' && <span className="badge badge-primary">Suggestion</span>}
                                <small className="float-right text-muted">{comment.com_date_created}</small>
                                {comment?.com_deleted_at && (
                                    <>
                                    <span className="float-right badge badge-danger" style={{cursor: "pointer"}}>
                                    <span>Commentaire supprimé</span>{' '}
                                        <i className="fa fa-ban"></i>
                                    </span>
                                    </>
                                )}
                            </h4>
                            <p>{comment.com_text
                                ? (shouldTruncate ? truncate(comment.com_text, truncateLength) : comment.com_text)
                                : '-'}</p>
                            <nav className="d-flex text-muted font-weight-bold">
                                <span className="icon mr-3" style={{cursor: "pointer"}} title="Identifiant">
                                                #{comment.com_id}
                                            </span>
                                            <span className="icon mr-3" title="Reponses" style={{cursor: "pointer"}}>
                                                <i className="fa fa-reply"></i>{' '}{comment.comments_count || 0}
                                            </span>
                                <span className="icon mr-3" title="Soutiens" style={{cursor: "pointer"}}>
                                                <i className="fa fa-heart"></i>{' '}{comment.soutiens_count || 0}
                                            </span>
                                <span className="icon mr-3 text-warning" title="Signalement" style={{cursor: "pointer"}}>
                                                <i className="fa fa-warning"></i>{' '}{comment.reports_count || 0}
                                            </span>
                                {comment.com_mute ? (<span className="icon mr-3 text-success" onClick={() => handleToggle(comment)} style={{cursor: 'pointer'}}>
                                                <i className="fa fa-volume-up"></i>{' '}Unmute
                                            </span>) : (<span className="icon mr-3 text-danger" onClick={() => handleToggle(comment)} style={{cursor: 'pointer'}}>
                                                <i className="fa fa-volume-off"></i>{' '}Mute
                                            </span>)}


                                {routeMatch.url != `/opinions/${comment.com_sond}` && (<Link className="text-muted ml-auto" to={`/opinions/${comment.com_sond}`}>
                                    <i className="fa fa-eye"></i>{' '}Voir l'opinion
                                </Link>)}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppComment;